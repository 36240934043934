.App {
  text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color:#fff;
	width:100%;
	max-width:380px;
}

.code input{
	width:30px;
	margin:8px;
	font-size:30px;
	padding:8px;
	text-transform: uppercase;
	text-align: center;
	border: 2px solid #BBBBFF;
	border-radius: 4px;
}

.code input:first-of-type {
	margin-left:0;
}

.code input:last-of-type {
	margin-right:0;
}

.action, .status, .message, .output {
	padding:15px 0;
	color:#fff;
	height:38px;
}

.App button {
	color:#fff;
	border:0.1em solid #FFFFFF;
	text-transform: uppercase;
	background-color: transparent;
	padding:10px 20px;
}

.output button {
	float:right;
	width:95px;
}

.output .inner {
	width:calc(100% - 130px);
	padding:10px;
	float:left;
	background-color:#fff;
	color:#000;
	text-align: left;
}

.message input {
	height:33px;
	padding:0 10px;
	width:calc(100% - 120px);
	margin-right:15px;
}

.App button:hover:enabled {
	color:#000;
	background-color:cyan;
	cursor:pointer;
}

input:disabled {
	background-color:#FFF;
}

button:disabled {
	color:darkgray;
}

@media only screen and (max-width: 375px) {
	.App {
		max-width:315px;
	}
	.code input {
		width: 30px;
		font-size:30px;
		margin:5px;
		padding:5px;
	}
}
